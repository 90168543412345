<template>
  <v-card :loading="loading" class="pb-1 pl-2 pr-2" outlined>
    <v-row class="mx-auto">
      <v-col
        :cols="revisionContent.length > 1 ? 6 : 12"
        v-for="(updateLine, index) in revisionContent"
        :key="index"
      >
        <v-card outlined class="mx-auto">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  required
                  label="Linha"
                  v-model="updateLine.id"
                  :items="lines.data"
                  item-text="nr_linha"
                  item-value="id_linha"
                >
                  <template v-slot:selection="data">
                    {{ data.item.ddi }} ({{ data.item.ddd }})
                    {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                    {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.ddi }} ({{ data.item.ddd }})
                          {{ data.item.nr_linha }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.nome_completo"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-select
                  :items="situationList"
                  item-text="name"
                  item-value="value"
                  v-model="updateLine.fields[0].value"
                  label="Perfil de Bloqueio"
                  outlined
                  dense
                ></v-select>
                <v-container
                  class="px-0"
                  fluid
                  dense
                  v-for="(option, switchIndex) in typeList"
                  :key="switchIndex"
                >
                  <v-checkbox
                    v-model="updateLine.fields[1].values"
                    :label="option.name"
                    :value="option.value"
                    class="mt-0"
                    dense
                  ></v-checkbox>
                </v-container>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["revision"],
  data() {
    return {
      revisionContent: [],
      loading: true
    };
  },

  async mounted() {
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadblockSituation().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadBlockType().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.revision["update_gol"]) {
      this.revisionContent = this.revision["update_gol"];
    }
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("onlineManager", ["situationList", "typeList"])
  },
  methods: {
    ...mapActions("packages", ["loadLines"]),
    ...mapActions("onlineManager", ["loadblockSituation", "loadBlockType"])
  }
};
</script>

<style scoped></style>
