<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">
          Selecione um ou mais pacotes
        </v-card-title>
        <v-card-text>
          <v-col cols="3" class="mb-n5">
            <v-autocomplete
              dense
              outlined
              required
              label="Selecione o tipo do pacote"
              :items="packageTypes"
              item-text="label"
              item-value="id"
              return-object
              @change="clickPackageType($event)"
              :disabled="loading"
              :loading="loading"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" v-if="showPackages" class="mb-n5">
            <v-autocomplete
              dense
              outlined
              required
              v-model="selectedPackage"
              label="Selecione um pacote"
              :items="packages"
              item-text="descricao"
              item-value="id"
              return-object
              :filter="customFilterPacote"
              @change="clickPackage($event)"
              :disabled="loadingPackages"
              :loading="loadingPackages"
            >
            </v-autocomplete>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      md="6"
      sm="12"
      v-for="(pacoteSelected, index) in packagesSelect"
      :key="pacoteSelected.descricao"
    >
      <v-card class="mx-auto">
        <v-card-title
          class="primary white--text"
          :title="pacoteSelected.descricao"
        >
          <div class="headerClass">
            {{ pacoteSelected.descricao }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePackage(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="primary white--text"
          ><h3>{{ pacoteSelected.valor }}</h3></v-card-subtitle
        >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="pacoteSelected.line"
                :items="lines"
                item-text="nr_linha"
                item-value="id"
                :rules="lineRules"
                :filter="customFilter"
                return-object
                @input="updateSelectedLine($event, pacoteSelected, index)"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="mx-auto">
              <v-alert
                border="left"
                class="text-center"
                dense
                type="warning"
                v-if="loadingLinePackages[index].value"
                dark
                >Carregando pacotes da linha!
              </v-alert>
              <template v-if="pacoteSelected.line.id_linha && !loadingLinePackages[index].value">
                <v-alert border="left" color="indigo" dark dense class="mb-0">
                  Pacotes da linha:
                </v-alert>
                <v-list>
                  <template v-for="(linePackage, index) in pacoteSelected.packages">
                    <v-list-item :key="linePackage.nome">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ linePackage.nome }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="index < pacoteSelected.packages.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>

                  <template v-if="pacoteSelected.packages.length < 1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Não possui
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Vue from "vue";
export default {
  props: ["field","index"],
  data() {
    return {
      packagesSelect: [],
      selectedPackage: [],
      loading: true,
      loadingLinePackages: [{
          value: false
      }],
      loadingPackages: false,
      show: false,
      lineRules: [v => !!v || "Linha é obrigatório"],
      showPackages: false
    };
  },
  async mounted() {
    if (this.field && this.field.component === "roamingComponent") {
      await this.loadingFilters({
        params: {
          type: "roaming"
        }
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
    } else {
      await this.loadingFilters().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters({
      packages: "packages/getPackages",
      linePackages: "packages/getLinePackages",
      lines: "packages/getLines",
      packageTypes: "packages/getPackageTypes",
    })
  },
  methods: {
    ...mapActions("packages", [
      "loadPackages",
      "loadLines",
      "loadLinePackages",
      "loadingFilters"
    ]),
    ...mapMutations({
      setModel: "forms/setModelNewPackages"
    }),
    async clickPackageType(packageType) {
      this.loadingPackages = true;
      this.showPackages = true;
      await this.loadPackages({ package_type: packageType.id }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      await this.loadLines().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.loadingPackages = false;
    },
    clickPackage(pacote) {
      Vue.config.warnHandler = function (msg, vm, trace) {
        // Faz nada para ignorar o aviso "Duplicate keys detected"
      };
      const newPackage = {
        valor: pacote.valor_assinatura,
        tipo_assinatura: pacote.tipo_assinatura,
        id_historico_assinatura: pacote.id_historico_assinatura,
        descricao: pacote.descricao,
        line: {},
        packages: []
      };

      this.loadingLinePackages.push({
        value: false
      });

      this.selectedPackage = [];
      this.packagesSelect.push(newPackage);
    },
    async updateSelectedLine(line, pacoteSelected, index) {
      this.show = this.show ? false : pacoteSelected;
      this.loadingLinePackages[index].value = true;
      if (line.id_linha.length) {
        pacoteSelected.packages = [];
        await this.loadLinePackages({ id_linha: line.id_linha }).catch(
          error => {
            this.$swal("Oops...", error.message, "error");
          }
        );
        pacoteSelected.packages = this.linePackages.data;

        this.updateModel();
      }
      this.show = false;
      this.loadingLinePackages[index].value = false;
    },
    updateModel() {
      const payload = {
        step: this.index,
        selected: {
          model: {lines: this.packagesSelect},
          revision: {lines: this.packagesSelect}
        }
      };
      this.setModel(payload);
    },
    closePackage(index) {
      if (index > -1) {
        this.packagesSelect.splice(index, 1);
      }
      this.loadingLinePackages.splice(index, 1);
      this.updateModel();
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
    customFilterPacote(item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      const descricao = item.descricao;
      return (
          regExp.test(descricao)
      );
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
