<template>
  <v-card :loading="loading" class="pb-1 pr-2 pl-2" outlined>
    <v-row>
      <v-col
        v-for="(revisao, indexRevision) in valueRevision"
        :key="indexRevision"
        :cols="6"
      >
        <v-card class="mx-auto" dense outlined>
          <v-card-title class="primary white--text">
            <div class="headerClass">
              {{revisao.line.ddi ? revisao.line.ddi+' ('+revisao.line.ddd+') '+revisao.line.nr_linha : 'Selecione uma linha' }}
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" align-self="center">
                <v-alert dense outlined color="primary">
                  {{ revisao.line.ddi }} ({{ revisao.line.ddd }})
                  {{ revisao.line.nr_linha }} -
                  {{ revisao.line.nome_completo }}
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-alert border="left" color="indigo" dark dense class="mb-0">
                  Pacotes da linha:
                </v-alert>
                <v-list>
                  <template
                    v-for="(linePackage, indexPackage) in revisao.packages"
                  >
                    <v-list-item :key="linePackage.nome">
                      <v-list-item-content>
                        <v-row class="align-center" dense>
                          <v-col md="8">
                            {{ linePackage.nome }}
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="indexPackage < revisao.packages.length - 1"
                      :key="indexPackage"
                    ></v-divider>
                  </template>

                  <template v-if="revisao.packages.length < 1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Não possui
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["revision"],
  data() {
    return {
      loading: true,
      valueRevision: []
    };
  },
  mounted() {
    this.valueRevision = this.revision['suspensao_perda_roubo'] ? this.revision['suspensao_perda_roubo'].lines : this.revision['voluntary_line_suspension'].lines;
    this.loading = false;
  }
};
</script>

<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
