<template>
  <v-row class="mx-auto">
    <v-col cols="5">
      <v-autocomplete
        v-model="search"
        :items="companies"
        item-text="nome"
        item-value="id_join"
        outlined
        dense
        clearable
        :label="label(index)"
        return-object
        @change="saveSelected"
        :loading="loading"
        :disabled="loading"
        :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
  props: ["index"],
  data() {
    return {
      search: {},
      loading: true
    };
  },
  async mounted() {
    await this.loadCompanies().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  },
  computed: {
    ...mapState("company", ["companies"]),
    ...mapGetters({
      label: "forms/getItemLabel",
      value: "forms/getItemValue"
    })
  },
  methods: {
    ...mapActions("company", ["loadCompanies"]),
    ...mapMutations({
      setModel: "forms/setModelCompany"
    }),
    saveSelected() {
      const paylout = {
        step: this.index,
        selected: this.search
      };
      this.setModel(paylout);
    }
  },
  created() {
    this.search = { id_join: this.value(this.index) };
  },
  watch: { 
    companies(comp) {
      if(comp != null && Array.isArray(comp) && comp.length === 1) {
        this.search = comp[0];
        this.saveSelected();
      } 
    } 
  }
};
</script>
