<template>
  <v-row class="mx-auto">
    <v-col cols="5">
    <v-autocomplete
      v-model="selected"
      :items="options(index)"
      item-text="text"
      outlined
      dense
      clearable
      :label="label(index)"
      return-object
      @change="setSelected"
    ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ['index'],
  data() {
    return {
      selected: {}
    };
  },
  computed: {
    ...mapGetters({
      label: "forms/getItemLabel",
      options: "forms/getItemOptions",
      currentValue: "forms/getItemValue"
    })
  },
  mounted() {
    this.selected = this.currentValue(this.index);
  },
  methods: {
    ...mapMutations({
      setModel: "forms/setModelSelectList"
    }),
    setSelected() {
      const payload = {
        step: this.index,
        selected: this.selected
      }
      this.setModel(payload);
    }
  },
};
</script>
<style scoped></style>
