<template>
  <v-card :loading="loading" class="pb-1 pl-2 pr-2" outlined>
    <v-row>
      <v-col
        v-for="(changeChipLine, indexRevision) in revisionContent"
        :key="indexRevision"
        :cols="6"
      >
        <v-card outlined class="mx-auto">
          <v-card-title class="primary white--text mb-3">
            <div class="headerClass">
              {{`${changeChipLine.line.ddi} (${changeChipLine.line.ddd}) ${changeChipLine.line.nr_linha}`}}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-autocomplete
                  dense
                  outlined
                  label="Linha"
                  v-model="changeChipLine.line"
                  :items="lines.data"
                  item-text="nr_linha"
                  item-value="id_linha"
                  :readonly="true"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }}) {{ data.item.nr_linha }} -
                  {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                          v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-text-field
                v-if="changeChipLine.customIccid"
                label="ICCID novo SIM Card"
                outlined
                dense
                :readonly="true"
                v-model="changeChipLine.customIccid"
              >
              </v-text-field>

              <v-text-field
                  v-if="!changeChipLine.customIccid"
                  label="ICCID novo SIM Card"
                  outlined
                  dense
                  :readonly="true"
                  v-model="changeChipLine.iccid['iccid']"
              >
              </v-text-field>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      revisionContent: [],
      loading: true
    };
  },

  async mounted() {
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.wizard['revision']["change_chip"]) {
      this.revisionContent = this.wizard['revision']["change_chip"];
    }
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("forms", ["wizard"]),
  },
  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
  }
};
</script>

<style scoped></style>
