<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <div v-if="typeComponent === 'package'">
        <v-card :loading="loading" dense outlined class="pb-1 pr-2 pl-2">
          <v-col cols="12">
            <v-card outlined>
              <CustomSelectPackageComponent
                :options="revisionContent"
                :desactiveFields="true"
                :showButtonAddNewCard="false"
                :showAllFields="showAllFields"
              >
              </CustomSelectPackageComponent>
            </v-card>
          </v-col>
        </v-card>
      </div>
    </v-col>
    <div v-if="typeComponent === 'plan'" style="display: contents">
      <v-col md="4" sm="6" v-for="(plan, index) in revisionContent" :key="index">
        <v-card class="mx-auto">
          <v-card-title class="primary white--text">
            <div class="title-content">
              {{ plan.plano.name }}
            </div>
          </v-card-title>
          <v-card-subtitle class="primary white--text">
            <h3>{{ plan.plano.price_format }}</h3>
          </v-card-subtitle>
          <v-card-text>
            <v-row align="center">
              <v-col class="text-center">
                <v-icon x-large>mdi-web</v-icon>
                <div class="title">{{ plan.plano.data }}</div>
                <div class="subtitle-2">Dados</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-phone-outgoing</v-icon>
                <div class="title">{{ plan.plano.voice }}</div>
                <div class="subtitle-2">Chamadas</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-message-text-outline</v-icon>
                <div class="title">{{ plan.plano.sms }}</div>
                <div class="subtitle-2">Mensagens</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  required
                  label="DDD"
                  v-model="plan.ddd"
                  :items="[plan.ddd]"
                  item-text="code"
                  :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                  readonly
                  return-object
                >
                  <template v-slot:selection="data">
                    {{ data.item.code }} - {{ data.item.region }}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.region"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <PersonField
                  v-if="showAllFields"
                  :label="'Selecione a Pessoa'"
                  :selectedPerson="plan.pessoa"
                  :readonly="true"
                ></PersonField>

                <iccid-field
                  v-if="showAllFields"
                  :readOnlyField="true"
                  :chipSelected="plan.iccid"
                ></iccid-field>

                <AccountField
                  v-if="showAllFields"
                  :readonly="true"
                  :selectedAccount="plan.conta"
                ></AccountField>

                <DeviceField
                  v-if="showAllFields"
                  :readonly="true"
                  :selectedDevice="plan.aparelho"
                ></DeviceField>

                <LineReference
                  v-if="showAllFields"
                  :readonly="true"
                  :selectedLine="plan.linha_referencia"
                ></LineReference>
              </v-col>
              <v-col cols="12" align="center" v-if="plan.plano.description">
                <v-btn text @click="showInfo(plan)" color="primary" small>
                  Mais informações
                  <v-icon>{{
                      show === plan ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show === plan">
              <v-divider></v-divider>

              <v-card-text>
                <div v-html="plan.plano.description"></div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CustomSelectPackageComponent from "@/components/forms/CustomSelectPackageComponent.vue";
import PersonField from "@/components/fields/PersonField.vue";
import IccidField from "@/components/fields/IccidField.vue";
import AccountField from "@/components/fields/AccountField.vue";
import DeviceField from "@/components/fields/DeviceField.vue";
import LineReference from "@/components/fields/LineReference.vue";

export default {
  components: { LineReference, DeviceField, AccountField, IccidField, PersonField, CustomSelectPackageComponent },
  props: ["revision","groupUser"],
  data() {
    return {
      revisionContent: [],
      loading: true,
      show: false,
      typeComponent: '',
      showAllFields: false
    };
  },

  async mounted() {
    await this.loadPlans().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadDDD().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadPersons().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.groupUser === 1) {
      this.showAllFields = true;
    }

    if (this.revision["activate_new_line"]) {
      this.typeComponent = this.wizard.model['activate_new_line'].typeComponent;
      this.revisionContent = this.revision["activate_new_line"];
    }
    this.loading = false;
  },
  computed: {
    ...mapState("plans", ["plans", "ddd"]),
    ...mapState("persons", ["persons"]),
    ...mapState("forms", ["wizard"]),
    ...mapState("icon", ["icons"])
  },
  methods: {
    ...mapActions("plans", ["loadPlans", "loadDDD"]),
    ...mapActions("persons", ["loadPersons"]),

    showInfo(plan) {
      this.show = this.show ? false : plan;
    }
  }
};
</script>

<style scoped></style>
