<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">{{ dynamicLabel }}</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      sm="6"
      v-for="(lineSelected, index) in lineSelect"
      :key="index"
    >
      <v-card outlined class="mx-auto">
        <v-card-title class="primary white--text">
          <div class="headerClass">
            {{lineSelected.cancela_linha && lineSelected.cancela_linha.ddi ? lineSelected.cancela_linha.ddi+' ('+lineSelected.cancela_linha.ddd+') '+lineSelected.cancela_linha.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="lineSelected.cancela_linha"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id"
                :rules="lineRules"
                :filter="filterObject"
                return-object
                @input="updateSelectedLine($event, lineSelected)"
                :disabled="loadingLinePackages"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  props: ['index'],
  data() {
    return {
      lineSelect: [],
      loading: true,
      show: false,
      dynamicLabel: "",
      lineRules: [v => !!v || "Linha é obrigatório"],
      loadingLinePackages: false
    };
  },
  async mounted() {
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.wizard.model["suspend_line"] || this.wizard.model["cancel_line"]) {
      this.wizard.forms.map(form => {
        if (form.type == "suspend_line" || form.type == "cancel_line")
          this.dynamicLabel = form.label;
      });
    }
    const newLine = {
      cancel_line: {}
    };
    this.lineSelect = [...this.lineSelect, newLine];

    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("forms", ["wizard"]),

    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
    ...mapMutations({
      setModel: "forms/setModelCancelLine"
    }),
    async clickAddNewLine() {         
      const newLine = {
        cancel_line: {}
      };
      
      if(this.lines != null && Array.isArray(this.lines.data) && this.lines.data.length === 1) {
        let cancelLineList = [{
          name: "line",
          type: "line",
          label: "Linha",
          value: this.lines.data[0].id_linha,
          id_pessoa: this.lines.data[0].id_pessoa,
          ddd: this.lines.data[0].ddd,
          ddi: this.lines.data[0].ddi,
          nr_linha: this.lines.data[0].nr_linha,
          nome_completo: this.lines.data[0].nome_completo
        }];
        
        newLine.cancela_linha = this.lines.data[0];
        this.updateSelectedLine(this.lines.data[0]);
        this.updateValues(cancelLineList);
      }     
      
      this.lineSelect = [...this.lineSelect, newLine];
      
    },
    async updateSelectedLine(lineSelected) {
      let cancelLineList = [];
      this.lineSelect.map(line => {
        cancelLineList.push({
          name: "line",
          type: "line",
          label: "Linha",
          value: line.cancela_linha.id_linha,
          id_pessoa: line.cancela_linha.id_pessoa,
          ddd: line.cancela_linha.ddd,
          ddi: line.cancela_linha.ddi,
          nr_linha: line.cancela_linha.nr_linha,
          nome_completo: line.cancela_linha.nome_completo
        });
      });
      this.show = this.show ? false : lineSelected;
      this.loadingLinePackages = true;

      if (cancelLineList.length) {
        this.updateValues(cancelLineList);
      }

      this.show = false;
      this.loadingLinePackages = false;
    },
    updateValues(cancelLineList) {
      const payload = {
        step: this.index,
        selected: cancelLineList
      };
      this.setModel(payload);
    },
    closeLine(index) {
      if (index > -1) {
        this.lineSelect.splice(index, 1);
      }
      this.updateSelectedLine();
    },
    filterObject(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
