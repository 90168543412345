<template>
  <v-row class="mx-auto">
    <v-col cols="5">
    <v-text-field
      v-model="value"
      :label="label(index)"
      outlined
      dense
      @change="setValueText()"
    />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ['index'],
  data() {
    return {
      value: ""
    };
  },
  computed: {
    ...mapGetters({ label: "forms/getItemLabel" }),
    ...mapGetters({ getItemText: "forms/getItemText" }),
  },
  mounted() {
    this.value = this.getItemText(this.index);
  },
  methods: {
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapMutations({ setModel: "forms/setModelText" }),
    setValueText() {
      const payload = {
        step: this.index,
        text: {
          value: this.value,
          text: this.value
        }
      };
      this.setModel(payload);
    },
  },
};
</script>
<style scoped></style>
