<template>
  <div>
    <v-autocomplete
      dense
      outlined
      required
      :label="label"
      v-model="value"
      :items="ddd.data"
      item-text="code"
      item-value="code"
      @input="setValueText"
    >
      <template v-slot:selection="data">
        {{ data.item.code }} - {{ data.item.region }}
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.code"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.region"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      value: ""
    };
  },
  computed: {
    ...mapState("plans", ["plans", "ddd"]),
    ...mapGetters({
      label: "forms/getItemLabel",
      getItemText: "forms/getItemText"
    })
  },
  methods: {
    ...mapActions("plans", ["loadDDD"]),
    ...mapMutations({
      setModel: "forms/setModelText"
    }),
    setValueText() {
      this.setModel({ value: this.value, text: this.value });
    }
  },
  created() {
    this.value = this.getItemText;
  },
  updated() {
    this.value = this.getItemText;
  },
  async mounted() {
    await this.loadDDD().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
  }
};
</script>
<style scoped></style>
