<template>
  <v-card :loading="loading" dense outlined class="pb-1 pl-2 pr-2 mt-2">
    <v-row>
      <v-col
        v-for="(lineSelected, indexRevision) in revisionContent"
        :key="indexRevision"
        sm="6"
      >
        <v-card outlined class="mx-auto" :loading="loading">
          <v-card-title class="primary white--text">
            <div class="headerClass">
              {{lineSelected.ddi+' ('+lineSelected.ddd+') '+lineSelected.nr_linha }}
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  label="Linha"
                  v-model="lineSelected.value"
                  :items="lines.data"
                  item-text="nr_linha"
                  item-value="id_linha"
                  :readonly="true"
                >
                  <template v-slot:selection="data">
                    {{ data.item.ddi }} ({{ data.item.ddd }})
                    {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                    {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.ddi }} ({{ data.item.ddd }})
                          {{ data.item.nr_linha }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.nome_completo"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["revision"],
  data() {
    return {
      revisionContent: [],
      loading: true,
      loading2: true
    };
  },

  async mounted() {
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.revision["cancel_line"]) {
      this.revisionContent = this.revision["cancel_line"];
    } else if (this.revision["suspend_line"]) {
      this.revisionContent = this.revision["suspend_line"];
    }
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"])
  },
  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"])
  }
};
</script>

<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
