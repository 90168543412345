<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">
          Selecione um ou mais planos
        </v-card-title>
        <v-card-text>
          <v-chip-group column multiple>
            <v-chip
              v-for="plan in plans.data"
              :key="plan.name"
              :value="plan.name"
              @click="clickPlan(plan)"
            >
              {{ plan.name }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col md="4" sm="6" v-for="(plan, index) in plansSelect" :key="index">
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="plan.name">
          <div class="headerClass">
            {{ plan.name }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePlan(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="primary white--text"
          ><h3>{{ plan.price_format }}</h3></v-card-subtitle
        >
        <v-card-text>
          <v-row align="center">
            <v-col class="text-center">
              <v-icon x-large>mdi-web</v-icon>
              <div class="title">{{ plan.data }}</div>
              <div class="subtitle-2">Dados</div>
            </v-col>
            <v-col class="text-center">
              <v-icon x-large>mdi-phone-outgoing</v-icon>
              <div class="title">{{ plan.voice }}</div>
              <div class="subtitle-2">Chamadas</div>
            </v-col>
            <v-col class="text-center">
              <v-icon x-large>mdi-message-text-outline</v-icon>
              <div class="title">{{ plan.sms }}</div>
              <div class="subtitle-2">Mensagens</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="DDD"
                v-model="plan.code"
                :items="ddd.data"
                item-text="code"
                item-value="code"
                :rules="dddRules"
                @change="updateValues()"
              >
                <template v-slot:selection="data">
                  {{ data.item.code }} - {{ data.item.region }}
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.code"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.region"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <v-text-field
                v-model="plan.amount"
                @input="updateValues()"
                type="number"
                outlined
                dense
                label="Qtde. Linhas"
                :rules="linhasRules"
              />
            </v-col>
            <v-col cols="12" align="center" v-if="plan.description">
              <v-btn text @click="showInfo(plan)" color="primary" small>
                Mais informações
                <v-icon>{{
                  show === plan ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="show === plan">
            <v-divider></v-divider>

            <v-card-text>
              <div v-html="plan.description"></div>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      plansSelect: [],
      loading: true,
      show: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      dddRules: [v => !!v || "DDD é obrigatório"],
      linhasRules: [
        v =>
          (v && v >= 0) || "A quantidade de linhas não pode ser menor que zero"
      ]
    };
  },
  computed: {
    ...mapState("plans", ["plans", "ddd"]),
    ...mapGetters({
      name: "forms/getItemName"
    }),
    ...mapState("icon", ["icons"])
  },
  methods: {
    ...mapActions("plans", ["loadPlans", "loadDDD"]),
    ...mapMutations({
      setModel: "forms/setModelPlans"
    }),

    clickPlan(plan) {
      const newPlan = {
        code: "",
        amount: 0,
        data: plan.data,
        data_format: plan.data_format,
        description: plan.description,
        id: plan.id,
        name: plan.name,
        price: plan.price,
        price_format: plan.price_format,
        sms: plan.sms,
        sms_format: plan.sms_format,
        voice: plan.voice,
        voice_format: plan.voice_format
      };
      this.plansSelect = [...this.plansSelect, newPlan];
    },

    closePlan(index) {
      if (index > -1) {
        this.plansSelect.splice(index, 1);
      }
    },

    updateValues() {
      this.setModel(this.plansSelect);
    },

    showInfo(plan) {
      this.show = this.show ? false : plan;
    }
  },
  async mounted() {
    await this.loadPlans().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadDDD().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    this.loading = false;
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
