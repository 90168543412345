<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-text>
          <v-col cols="3">
            <v-autocomplete
                dense
                outlined
                required
                label="Selecione o tipo do pacote"
                :items="packageTypes.data"
                item-text="label"
                item-value="id"
                return-object
                @change="clickPackageType($event)"
                :disabled="loading"
                :loading="loading"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" v-if="showPackages">
            <v-autocomplete
                dense
                outlined
                required
                v-model="selectedPackage"
                label="Selecione um pacote"
                :items="packages.data"
                item-text="descricao"
                item-value="id"
                return-object
                :filter="customFilterPacote"
                @change="clickPackage($event)"
                :disabled="loadingPackages"
                :loading="loadingPackages"
            >
            </v-autocomplete>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      md="6"
      sm="12"
      v-for="(pacoteSelected, index) in packagesSelect"
      :key="pacoteSelected.descricao"
    >
      <v-card class="mx-auto">
        <v-card-title
          class="primary white--text"
          :title="pacoteSelected.descricao"
        >
          <div class="headerClass">
            {{ pacoteSelected.descricao }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="closePackage(index, pacoteSelected.descricao)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="primary white--text"
          ><h3> R$ {{ pacoteSelected.valor_assinatura }}</h3></v-card-subtitle
        >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="pacoteSelected.line"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id"
                :rules="lineRules"
                :filter="customFilter"
                return-object
                @input="updateSelectedLine($event, pacoteSelected, index)"
                :disabled="loadingLinePackages[index].value"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <ListPackage
                  :lineSelected="pacoteSelected"
                  :loadingPackage="loadingLinePackages[index].value"
              ></ListPackage>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import ListPackage from "../package/ListPackage.vue";
import Vue from "vue";
export default {
  components: {ListPackage},
  props: ['index'],
  data() {
    return {
      packagesSelect: [],
      packagesModel: [],
      selectedPackage: [],
      loading: true,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      show: false,
      lineRules: [v => !!v || "Linha é obrigatório"],
      loadingLinePackages: [],
      loadingPackages: false,
      showPackages: false
    };
  },
  async mounted() {
    await this.loadingFilters().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["packages", "lines", "packageTypes"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages",
      linhaReferencia: "packages/getLineReference"
    })
  },
  methods: {
    ...mapActions("packages", [
      "loadPackages",
      "loadLines",
      "loadLinePackages",
      "loadingFilters",
      "loadLineReference"
    ]),
    ...mapMutations({
      setModel: "forms/setModelPackages"
    }),
    async clickPackageType(packageType) {
      this.loadingPackages = true;
      this.showPackages = true;
      await this.loadPackages({ package_type: packageType.id }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      await this.loadLines().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.loadingPackages = false;
    },

    clickPackage(pacote) {
      Vue.config.warnHandler = function (msg, vm, trace) {
        // Faz nada para ignorar o aviso "Duplicate keys detected"
      };
      this.loadingLinePackages.push({ value: false });

      const newPackage = {
        valor_assinatura: pacote.valor_assinatura,
        tipo_assinatura: pacote.tipo_assinatura,
        descricao: pacote.descricao,
        id_historico_assinatura: pacote.id_historico_assinatura,
        linha_referencia: pacote.linha_referencia,
        packages: [],
        line: {}
      };
      this.selectedPackage = [];
      this.packagesSelect.push(newPackage);
      this.updateModel();
    },
    async updateSelectedLine(line, pacoteSelected, index) {
      this.loadingLinePackages[index].value = true;
      if (line.id_linha.length) {
        pacoteSelected.packages = [];
        await this.loadLinePackages({ id_linha: line.id_linha }).catch(
          error => {
            this.$swal("Oops...", error.message, "error");
          }
        );
        pacoteSelected.packages = this.linePackages.data;
        pacoteSelected.packages.map(e => {
          if (pacoteSelected.id == e.id_pacote) {
            this.lineRules = [
              "Este pacote já esta associado a linha selecionada."
            ];
          }
        });

        pacoteSelected.linha_referencia = [];
        await this.loadLineReference({ id_linha: line.id_linha, id_historico_assinatura: pacoteSelected.id_historico_assinatura }).catch(
            error => {
              this.$swal("Oops...", error.message, "error");
            }
        );
        pacoteSelected.linha_referencia = this.linhaReferencia ? this.linhaReferencia : [];
      }

      // this.updateModel()
      this.loadingLinePackages[index].value = false;
    },
    updateModel() {
      const payload = {
        step: this.index,
        selected: {
          model: this.packagesSelect,
          revision: this.packagesSelect
        }
      };
      this.setModel(payload);
    },
    closePackage(index, pacote) {
      if (index > -1) {
        this.packagesSelect.splice(index, 1);
        this.loadingLinePackages.splice(index, 1);
        delete this.packagesModel[index];
      }
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
    customFilterPacote(item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      const descricao = item.descricao;
      return (
          regExp.test(descricao)
      );
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
