<template>
  <div>
  <v-row class="mx-auto">
    <v-col cols="5">
      <v-autocomplete
        v-model="search"
        :items="users.data"
        item-text="nome"
        item-value="id_join"
        outlined
        dense
        clearable
        :label="label(index)"
        :search-input.sync="searchUser"
        return-object
        @change="saveSelected"
        :loading="loading"
        :disabled="loading"
        :filter="filterObject"
        :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório', v => (v && v.id_join && v.id_join !== '') || 'Obrigatório']"
      >
        <template #no-data>
          <v-list-item v-if="permissionAdmSystem && searchUser != null && searchUser.length > 0" @click="createNewUser(searchUser)">
            <span class="subheading mr-2">Criar usuário</span>{{ searchUser }}
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
  <custom-dialog-new-user-component
    v-if="permissionAdmSystem && showDialogNewUser"
    v-model="showDialogNewUser"
    :newUser="newUser"
    :listUsers="users.data"
    @close="showDialogNewUser = !showDialogNewUser"
    @newUserData="newUserData"
  ></custom-dialog-new-user-component>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import CustomDialogNewUserComponent from "@/components/forms/CustomRequesterComponent/CustomDialogNewUserComponent.vue";

export default {
  components: { CustomDialogNewUserComponent },
  props: ["index","groupUser"],
  data() {
    return {
      search: {},
      searchUser: '',
      loading: true,
      showDialogNewUser: false,
      newUser: '',
      listData: [],
      permissionAdmSystem: false,
    };
  },
  async mounted() {
    await this.loadUsers().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.groupUser && this.groupUser === 1) {
      this.permissionAdmSystem = true;
    }

    this.loading = false;
  },
  computed: {
    ...mapState("user", ["users"]),
    ...mapGetters({
      label: "forms/getItemLabel",
      value: "forms/getItemValue"
    })
  },
  methods: {
    ...mapActions("user", ["loadUsers"]),
    ...mapMutations({
      setModel: "forms/setModelRequester"
    }),

    newUserData(data) {
      this.search = data;
      this.saveSelected();
    },

    createNewUser(user) {
      this.showDialogNewUser = true;
      this.newUser = user;
      this.searchUser = '';
    },

    saveSelected() {
      this.setModel({
        index: this.index,
        id: this.search.id_join,
        usuario: this.search.nome
      });
    },

    filterObject(item, queryText) {
      const text = this.clearText(item.nome);
      const searchText = this.clearText(queryText);
      return text.indexOf(searchText) > -1;
    },

    clearText(text) {
      let cleanText = text.toLowerCase();
      cleanText = cleanText.replace(new RegExp(/\s/g), " ");
      cleanText = cleanText.replace(new RegExp(/[àáâãäå]/g), "a");
      cleanText = cleanText.replace(new RegExp(/æ/g), "ae");
      cleanText = cleanText.replace(new RegExp(/ç/g), "c");
      cleanText = cleanText.replace(new RegExp(/[èéêë]/g), "e");
      cleanText = cleanText.replace(new RegExp(/[ìíîï]/g), "i");
      cleanText = cleanText.replace(new RegExp(/ñ/g), "n");
      cleanText = cleanText.replace(new RegExp(/[òóôõö]/g), "o");
      cleanText = cleanText.replace(new RegExp(/œ/g), "oe");
      cleanText = cleanText.replace(new RegExp(/[ùúûü]/g), "u");
      cleanText = cleanText.replace(new RegExp(/[ýÿ]/g), "y");
      cleanText = cleanText.replace(new RegExp(/\W/g), " ");

      return cleanText;
    }
  },
  created() {
    this.search = { id_join: this.value(this.index) };
  },
  watch: {
    users(usr) {
      if(usr != null && Array.isArray(usr.data) && usr.data.length === 1) {
        this.search.id_join = usr.data[0].id_join;
        this.search.nome = usr.data[0].nome;
        this.saveSelected();
      } 
    } 
  }
};
</script>
<style scoped></style>
