<template>
  <v-sheet
    class="mx-auto mt-5 py-2"
    elevation="4"
  >
    <v-col class="col-md-12">
      <v-card-title class="subtitle-1 text--primary pb-0">
        Revise o e-mail que será enviado ao fornecedor
      </v-card-title>
    </v-col>

    <v-col v-if="email_loading" class="col-md-12 d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-col>

    <v-col class="col-md-12" v-if="!email_loading">
      <v-col cols="12" class="pt-0 pb-0">
        <v-text-field
            label="Assunto"
            v-model="subjectEmail"
            @change="changeValues(true)"
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-col>
    <v-col cols="12" class="py-0" v-if="!email_loading">
      <v-col md="12" cols="12">
        <quill-editor
            :content="messageEmail"
            :options="editorOption"
            v-model="messageEmail"
            @change="changeValues(true)"
        />
      </v-col>
    </v-col>
  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  props: {
    isEdited: {
      type: Boolean,
      default: false
    },
    create_solicitaion: {
      type: Boolean,
      default: false
    },
    selectedEmail: {
      default: ''
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      email_loading: false,
      emailSignature: "",
      subjectEmail: "",
      messageEmail: "",
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
    };
  },

  mounted() {
  },

  computed: {
    ...mapState("forms", ["form_email"]),
    ...mapState("solicitation", ["solicitation_form_email"]),
  },
  methods: {
    ...mapActions("solicitation", ["loadSolicitationFormEmail",]),

    async loadFormEmail(params) {
      this.email_loading = true;
      await this.loadSolicitationFormEmail(params);
      if (this.solicitation_form_email.success && this.solicitation_form_email.data) {
        this.subjectEmail = this.solicitation_form_email.data.subjectEmail;
        this.messageEmail = this.solicitation_form_email.data.messageEmail;
      }

      this.changeValues(false);
      this.email_loading = false;
    },
    changeValues(customEdit) {
      this.$emit("changeMessageEmail", {
        subjectEmail: this.subjectEmail,
        messageEmail: this.messageEmail,
        customEdit: customEdit
      })
    },
  }
};
</script>

<style scoped>
.removeOpacity:focus::before {
  opacity: 0 !important;
}
</style>
