<template>
  <v-card :loading="loading" class="pb-1 pl-2 pr-2" outlined>
    <v-row>
      <v-col
        v-for="(revisao, indexRevision) in revision['line_disable_package']"
        :key="indexRevision"
        :cols="revision['line_disable_package'].length > 1 ? 6 : 12"
      >
        <v-card class="mx-auto" dense outlined>
          <v-card-text>
            <v-card-title class="primary white--text" :title="'teste'">
              <div class="headerClass">
                {{ revisao.line.ddi+' ('+revisao.line.ddd+') '+revisao.line.nr_linha }}
              </div>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeLine(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-row>
              <v-col cols="12" align-self="center" class="mb-n5">
                <v-alert dense outlined color="primary">
                  {{ revisao.line.ddi }} ({{ revisao.line.ddd }})
                  {{ revisao.line.nr_linha }} -
                  {{ revisao.line.nome_completo }}
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-alert border="left" color="indigo" dark dense class="mb-0">
                  Pacotes da linha:
                </v-alert>
                <v-list>
                  <template
                    v-for="(linePackage, indexPackage) in revisao.packages"
                  >
                    <v-list-item :key="linePackage.nome">
                      <v-list-item-content>
                        <v-row class="align-center" dense>
                          <v-col md="10">
                            {{ linePackage.nome }}
                          </v-col>
                          <v-col md="2">
                            <v-switch
                              class="mt-0"
                              dense
                              v-model="linePackage.desativar"
                              hide-details
                              inset
                              readonly
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="indexPackage < revisao.packages.length - 1"
                      :key="indexPackage"
                    ></v-divider>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["revision"],
  data() {
    return {
      revisionContent: [],
      loading: true
    };
  },
  mounted() {
    this.loading = false;
  }
};
</script>

<style scoped></style>
