<template>
  <v-row class="mx-auto">
    <v-card-text>
      <h3>{{label(index)}}</h3>
    </v-card-text>
    <v-col cols="12">
      <v-radio-group
        v-model="row"
        row
        @change="setSelected"
        style="margin-top: -1%;"
      >
        <v-radio
          label="Sim"
          value="true"
        ></v-radio>
        <v-radio
          label="Não"
          value="false"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ['index'],
  data() {
    return {
      selected: {},
      row: "false"
    };
  },
  computed: {
    ...mapGetters({
      label: "forms/getItemLabel",
      currentValue: "forms/getItemValue"
    })
  },
  mounted() {
    this.setSelected();
  },
  methods: {
    ...mapMutations({
      setModel: "forms/setModelRadio"
    }),
    setSelected() {
      const payload = {
        step: this.index,
        text: {
          value: this.row,
          text: this.row == "true" ? 'Sim' : 'Não',
        }
      }
      this.setModel(payload);
    }
  },
};
</script>
<style scoped></style>
