<template>
  <v-row class="mx-auto">
    <v-col cols="5">
    <v-text-field
      v-model="value"
      :label="label(index)"
      outlined
      dense
      @change="setValueText"
      type="number"
      :rules="rulesNumber"
    ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ['index'],
  data() {
    return {
      value: "",
      rulesNumber: [v => !isNaN(parseFloat(v)) || "Insira um valor numerico."]
    };
  },
  computed: {
    ...mapGetters({
      label: "forms/getItemLabel",
      getItemText: "forms/getItemText"
    })
  },
  mounted() {
    this.value = this.getItemText(this.index);
  },
  methods: {
    ...mapMutations({
      setModel: "forms/setModelText"
    }),
    setValueText() {
      const payload = {
        step: this.index,
        text: {
          value: this.value,
          text: this.value,
        }
      };
      this.setModel(payload);
    }
  },
};
</script>
<style scoped></style>
