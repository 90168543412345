<template>
  <v-card class="mx-auto" outlined :loading="loading">
    <v-card-title> Revise os dados preenchidos </v-card-title>
    <v-card-text>
      <v-list-item v-for="(item, i) in forms_resumo" :key="i">
        <v-list-item-content v-if="item.text !== '' || item.type === 'text'">
          <v-list-item-title>
            <strong>{{ item.name }}: </strong>
            <span v-if="item.text != ''" v-html="item.text"></span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-else>
          <strong>{{ item.name }}: </strong>
          <ChangeChipComponent
            v-if="wizard.revision['change_chip']"
            :revision="wizard.revision"
          />
          <PackagesMultipleComponent
            v-if="wizard.revision['roaming']"
            :revision="wizard.revision"
          />
          <LineDisablePackageComponent
            v-if="wizard.revision['line_disable_package']"
            :revision="wizard.revision"
          />
          <CancelLineComponent
            v-if="
              wizard.revision['cancel_line'] || wizard.revision['suspend_line']
            "
            :revision="wizard.revision"
          />

          <ReactivateLineComponent
            v-if="wizard.revision['reactivate_line']"
            :revision="wizard.revision"
          />
          <VoluntaryLineSuspensionComponent
            v-if="wizard.revision['voluntary_line_suspension'] || wizard.revision['suspensao_perda_roubo']"
            :revision="wizard.revision"
          />
          <OnlineManagerComponent
            v-if="wizard.revision['update_gol']"
            :revision="wizard.revision"
          />
          <ActivateNewLineComponent
            v-if="wizard.revision['activate_new_line']"
            :revision="wizard.revision"
            :groupUser="groupUser"
          />
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import PackagesMultipleComponent from "@/components/wizardReview/customFields/PackagesMultipleComponent";
import LineDisablePackageComponent from "@/components/wizardReview/customFields/LineDisablePackageComponent";
import CancelLineComponent from "@/components/wizardReview/customFields/CancelLineComponent";
import OnlineManagerComponent from "@/components/wizardReview/customFields/OnlineManagerComponent";
import ChangeChipComponent from "@/components/wizardReview/customFields/ChangeChipComponent";
import ReactivateLineComponent from "@/components/wizardReview/customFields/ReactivateLineComponent";
import VoluntaryLineSuspensionComponent from "@/components/wizardReview/customFields/VoluntaryLineSuspensionComponent";
import ActivateNewLineComponent from "@/components/wizardReview/customFields/ActivateNewLineComponent";
import { mapGetters, mapState } from "vuex";

function getResumo(forms, model) {
  let itens = [];
  for (let i in forms) {
    let item = forms[i];
    let text = model[item.name].text || "";
    let type = item.type;

    if (item.type === "plans") {
      if (model.plans.values) {
        model.plans.values.forEach(function(object) {
          if (object.amount > 0) {
            text += `<br>${object.name}: DDD ${object.code} | qtde ${object.amount}`;
          }
        });
      }
    }
    if (item.type === "busca_pacote") {
      if (model.busca_pacote) {
        model.busca_pacote.forEach(function(pacote) {
          text += `<br>${pacote.descricao}: ${pacote.line.ddi} (${pacote.line.ddd}) ${pacote.line.nr_linha} | ${pacote.line.nome_completo}`;
        });
      }
    }
    if (item.type === "linha") {
      const line = model[item.name];
      text += `${line.ddi} (${line.ddd}) ${line.nr_linha} | ${line.nome_completo}`;
    }
    if (item.type === "troca_ddd" || item.type === "change_number_undue_divulgation") {
      if (model.troca_ddd.values) {
        model.troca_ddd.values.forEach(function(ddd) {
          text += `<br>Linha: ${ddd.line.ddd} (${ddd.line.nr_linha})`;
          text += `<br>Mudança para: ${ddd.mudancaPara.label}`;
          text += `<br>Novo DDD: ${ddd.ddd.code}`;
          if (ddd.iccid.iccid) {
            text += `<br>ICCID: ${ddd.iccid.iccid}`;
          }
          if (ddd.conta.cod_conta) {
            text += `<br>Conta: ${ddd.conta.cod_conta}`;
          }
          if (ddd.person.checkbox) {
            text += `<br>Nova Pessoa: ${ddd.person.nome_completo}`;
          }
          text += "<br>";
        });
      }
    }
    if (item.type === "linha_desativa_pacote") {
      if (model.linha_desativa_pacote.values) {
        model.linha_desativa_pacote.values.forEach(function(object) {
          text += `<br>${object.line.ddi} (${object.line.ddd})
                       ${object.line.nr_linha} | ${object.line.nome_completo}<br>`;
          text += "Pacotes a serem desativados: ";
          object.packages.forEach(pacote => {
            if (pacote.desativar === true) {
              text += `${pacote.nome} | `;
            }
          });
          text += "<br>";
        });
      }
    }
    itens.push({
      name: item.label,
      text: text,
      type: type
    });
  }
  return itens;
}
export default {
  props: {
    groupUser: {
      type: Number,
      default: 0,
    }
  },
  components: {
    PackagesMultipleComponent,
    LineDisablePackageComponent,
    CancelLineComponent,
    ChangeChipComponent,
    ReactivateLineComponent,
    VoluntaryLineSuspensionComponent,
    OnlineManagerComponent,
    ActivateNewLineComponent
  },
  data() {
    return {
      forms_resumo: [],
      loading: true
    };
  },
  mounted() {
    this.forms_resumo = getResumo(this.wizard_data.data, this.model);
    this.loading = false;
  },
  computed: {
    ...mapState("solicitation", ["wizard_data"]),
    ...mapState("forms", ["wizard"]),
    ...mapGetters({
      model: "forms/getModel"
    })
  }
};
</script>

<style scoped></style>
