<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <CustomSelectComponent
          :cols="3"
          :options="optionsSelectCustom"
          @change="changeSelectCustom"
        ></CustomSelectComponent>

        <CustomSelectPackageComponent
          v-if="showPackage"
          :showAllFields="showAllfields"
          :page="'wizard'"
          @updateValues="updateValues"
        >
        </CustomSelectPackageComponent>

        <div v-if="showPlan">
          <v-card-title class="subtitle-1">
            Selecione um ou mais planos
          </v-card-title>
          <v-card-text>
            <v-chip-group column multiple>
              <v-chip
                v-for="plan in plans.data"
                :key="plan.name"
                :value="plan.name"
                @click="clickPlan(plan)"
              >
                {{ plan.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </div>
      </v-card>
    </v-col>
    <div v-if="showPlan" style="display: contents;">
      <v-col md="4" sm="6" v-for="(plan, index) in plansSelect" :key="index">
        <v-card class="mx-auto">
          <v-card-title class="primary white--text">
            <div class="title-content">
              {{ plan.plano.name }}
            </div>
            <v-btn icon class="close-button" @click="closePlan(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="primary white--text">
            <h3>{{ plan.plano.price_format }}</h3>
          </v-card-subtitle>
          <v-card-text>
            <v-row align="center">
              <v-col class="text-center">
                <v-icon x-large>mdi-web</v-icon>
                <div class="title">{{ plan.plano.data }}</div>
                <div class="subtitle-2">Dados</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-phone-outgoing</v-icon>
                <div class="title">{{ plan.plano.voice }}</div>
                <div class="subtitle-2">Chamadas</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-message-text-outline</v-icon>
                <div class="title">{{ plan.plano.sms }}</div>
                <div class="subtitle-2">Mensagens</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  required
                  label="DDD"
                  v-model="plan.ddd"
                  :items="ddd.data"
                  item-text="code"
                  return-object
                  :rules="dddRules"
                  @change="updateValues()"
                >
                  <template v-slot:selection="data">
                    {{ data.item.code }} - {{ data.item.region }}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.region"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <PersonField
                  v-if="showAllfields"
                  :label="'Selecione a Pessoa'"
                  @save="saveDataComponent($event, index, 'pessoa')"
                ></PersonField>

                <iccid-field
                  v-if="showAllfields"
                  :ddd="plan.ddd.code"
                  :rules="objectRule"
                  @changeData="saveDataComponent($event, index, 'iccid')"
                ></iccid-field>

                <AccountField
                  v-if="showAllfields"
                  :rules="objectRule"
                  @saveAccount="saveDataComponent($event, index, 'conta')"
                ></AccountField>

                <DeviceField
                  v-if="showAllfields"
                  @save="saveDataComponent($event, index, 'aparelho')"
                ></DeviceField>

                <LineReference
                  v-if="showAllfields"
                  @save="saveDataComponent($event, index, 'linha_referencia')"
                ></LineReference>
              </v-col>
              <v-col cols="12" align="center" v-if="plan.plano.description">
                <v-btn text @click="showInfo(plan)" color="primary" small>
                  Mais informações
                  <v-icon>{{
                      show === plan ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show === plan">
              <v-divider></v-divider>

              <v-card-text>
                <div v-html="plan.plano.description"></div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import CustomSelectComponent from "@/components/forms/CustomSelectComponent.vue";
import CustomSelectPackageComponent from "@/components/forms/CustomSelectPackageComponent.vue";
import PersonField from "@/components/fields/PersonField.vue";
import IccidField from "@/components/fields/IccidField.vue";
import AccountField from "@/components/fields/AccountField.vue";
import DeviceField from "@/components/fields/DeviceField.vue";
import LineReference from "@/components/fields/LineReference.vue";
export default {
  components: { LineReference, DeviceField, AccountField, IccidField, PersonField, CustomSelectPackageComponent, CustomSelectComponent },
  props: ['index','groupUser'],
  data() {
    return {
      plansSelect: [],
      loading: true,
      show: false,
      showPlan: false,
      showPackage: false,
      showAllfields: false,
      objectRule: [v => (v && Object.keys(v).length > 0) || 'Obrigatório'],
      packagesSelected: [],
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      optionsSelectCustom: [
        { text: 'Plano', value: 'plan' },
        { text: 'Pacote', value: 'package' }
      ],
      dddRules: [v => (v && Object.keys(v).length > 0) || 'Obrigatório'],
      linhasRules: [
        v =>
          (v && v >= 0) || "A quantidade de linhas não pode ser menor que zero"
      ]
    };
  },
  async mounted() {
    try {
      await Promise.all([
        this.loadPlans(),
        this.loadDDD(),
        this.loadPersons()
      ]);
      if (this.groupUser === 1) {
        this.showAllfields = true;
      }
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
    this.loading = false;
  },
  computed: {
    ...mapState("plans", ["plans", "ddd"]),
    ...mapState("persons", ["persons"]),
    ...mapGetters({
      name: "forms/getItemName"
    }),
    ...mapState("icon", ["icons"])
  },
  methods: {
    ...mapActions("plans", ["loadPlans", "loadDDD"]),
    ...mapActions("persons", ["loadPersons"]),
    ...mapMutations({
      setModel: "forms/setModelActivateLine"
    }),

    saveDataComponent(data, index, component) {
      this.plansSelect[index][component] = data;
      this.updateValues();
    },

    changeSelectCustom(data) {
      if (data.value === 'package') {
        this.showPackage = true;
        this.showPlan = false;
      } else {
        this.showPackage = false;
        this.showPlan = true;
      }
      this.plansSelect = [];
      this.updateValues();
    },

    clickPlan(plan) {
      if(this.persons != null && Array.isArray(this.persons.data) && this.persons.data.length === 1) {
        plan.person = this.persons.data[0];
      }

      const newPlan = {
        aparelho: {},
        conta: {},
        ddd: {},
        iccid: {},
        linha_referencia: {},
        pessoa: {},
        plano: plan
      };

      this.plansSelect = [...this.plansSelect, newPlan];
    },

    closePlan(index) {
      if (index > -1) {
        this.plansSelect.splice(index, 1);
      }
    },

    updateValues(packagesSelected) {
      let type = 'plan';
      let data = this.plansSelect;
      if (packagesSelected && packagesSelected.length > 0) {
        type = 'package';
        data = packagesSelected
      }
      this.setModel({
        step: this.index,
        selected: data,
        type: type,
      });
    },

    showInfo(plan) {
      this.show = this.show ? false : plan;
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-content {
  white-space: normal;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
