<template>
  <v-card :loading="loading" class="pb-1 pr-2 pl-2" outlined>
    <v-row>
      <v-col
        v-for="(lineSelected, indexRevision) in revision['reactivate_line'].lines"
        :key="indexRevision"
        :cols="6"
      >
        <v-card outlined class="mx-auto">
          <v-card-title class="primary white--text" :title="'Reativação de linha suspensa'">
            <div class="headerClass">
              {{lineSelected.line.ddi ? lineSelected.line.ddi+' ('+lineSelected.line.ddd+') '+lineSelected.line.nr_linha : 'Selecione uma linha' }}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    dense
                    outlined
                    required
                    label="Linha"
                    v-model="lineSelected.line"
                    :items="lineSelected.line"
                    item-text="nr_linha"
                    item-value="id"
                    return-object
                >
                  <template v-slot:selection="data">
                    {{ data.item.ddi }} ({{ data.item.ddd }})
                    {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                    {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.ddi }} ({{ data.item.ddd }})
                          {{ data.item.nr_linha }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-html="data.item.nome_completo"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <ListPackage
                  v-if="true"
                  :loadingPackage="false"
                  :lineSelected="lineSelected"
              >
              </ListPackage>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ListPackage from "@/components/package/ListPackage";
export default {
  components: {ListPackage},
  props: ["revision"],
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    this.loading = false;
  }
};
</script>

<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>