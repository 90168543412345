<template>
  <div>
    <v-autocomplete
      v-model="search"
      :items="provider_list.data"
      item-text="name"
      outlined
      dense
      clearable
      :label="label"
      return-object
      @change="saveSelected"
      autofocus
      :loading="loading"
      :disabled="loading"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      search: {},
      loading: true
    };
  },
  async mounted() {
    await this.loadProvider().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  },
  computed: {
    ...mapState("provider", ["provider_list"]),
    ...mapGetters({
      label: "forms/getItemLabel",
      text: "forms/getItemText"
    })
  },
  methods: {
    ...mapActions("provider", ["loadProvider"]),
    ...mapMutations({
      setModel: "forms/setModelProvider"
    }),
    saveSelected() {
      this.setModel(this.search);
    }
  },
  watch: {
    provider_list(prov) {
      if(prov != null && Array.isArray(prov.data) && prov.data.length >= 1) {
        this.search = prov.data[0];
        this.saveSelected();
      } 
    } 
  }
};
</script>
