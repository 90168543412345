<template>
  <v-container>
    <v-row>
      <v-col :cols="cols">
        <v-select
          v-model="selectedOption"
          :items="options"
          return-object
          :label="label"
          outlined
          @change="changeOption"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    cols: {
      type: Number,
      default: 3
    },
    options: {
      type: Array,
    },
    label: {
      type: String,
      default: "Escolha uma opção"
    }
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  methods: {
    changeOption() {
      this.$emit("change", this.selectedOption);
    }
  }

}
</script>
<style>
</style>