<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <v-card outlined>
        <v-card-title>
          <h3>
            <v-icon class="mr-3" color="primary">mdi-folder-pound</v-icon
            >Abertura de solicitação
          </h3>
        </v-card-title>
        <v-card-text>
          <WizardComponent />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import WizardComponent from "@/components/WizardComponent";
export default {
  components: {
    WizardComponent
  }
};
</script>

<style scoped></style>
