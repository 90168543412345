<template>
  <v-row class="mx-auto">
    <v-col cols="5">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          :label="label(index)"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          dense
          outlined
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        @input="menu = false"
        @change="setValueDate"
      ></v-date-picker>
    </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ['index'],
  data: vm => ({
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu: false
  }),

  computed: {
    ...mapGetters({
      label: "forms/getItemLabel"
    }),
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  mounted() {
    this.setValueDate();
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    }
  },

  methods: {
    ...mapMutations({
      setModel: "forms/setModelText"
    }),

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    setValueDate() {
      const payload = {
        step: this.index,
        text: {
          value: this.computedDateFormatted,
          text: this.computedDateFormatted,
        }
      };
      this.setModel(payload);
    }
  }
};
</script>
<style scoped></style>
